import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import getMarqueeCreatives from "api/requests/consumerApi/getMarqueeCreatives";
import { Creative } from "custom-types/AdMarquee";
import { getCoordinates } from "redux/selectors/location";

const useFetchMarqueeCreatives = (pageType: string) => {
  const [creatives, setCreatives] = useState<Creative[]>([]);
  const [loading, setLoading] = useState(true);
  const { lat, lon } = useSelector(getCoordinates);
  useEffect(() => {
    getMarqueeCreatives(pageType, lat, lon).then((data) => {
      if (data) {
        setCreatives(data);
      }
      setLoading(false);
    });
  }, []);
  return {
    creatives,
    loading,
  };
};

export default useFetchMarqueeCreatives;
