import { Category } from "constants/events";
import { AdMarqueePageLocation } from "custom-types/AdMarquee";

const houseCategories = {
  [AdMarqueePageLocation.HOME_PAGE]: Category.homepageHouseAd,
  [AdMarqueePageLocation.STRAINS_LIST_PAGE]: Category.strainListHouseAd,
  [AdMarqueePageLocation.DEALS_PAGE]: Category.dealsPageListHouseAd,
};

const paidCategories = {
  [AdMarqueePageLocation.HOME_PAGE]: Category.homepagePaidAd,
  [AdMarqueePageLocation.STRAINS_LIST_PAGE]: Category.strainListPaidAd,
  [AdMarqueePageLocation.DEALS_PAGE]: Category.dealsPageListPaidAd,
};

const setTrackingCategory = (
  isPaidAd: boolean,
  pageType: AdMarqueePageLocation,
) => {
  return isPaidAd ? paidCategories[pageType] : houseCategories[pageType];
};

export default setTrackingCategory;
