import "@splidejs/react-splide/css";

import React from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

import { fallBackHouseAd } from "api/requests/consumerApi/getMarqueeCreatives";
import {
  AdMarqueePageLocation,
  AdMarqueeType,
  Creative,
} from "custom-types/AdMarquee";
import useFetchMarqueeCreatives from "hooks/useFetchMarqueeCreatives";
import setTrackingCategory from "utils/marqueeAd/setTrackingCategory";

import MarqueeAdCard, {
  MarqueeAdType,
} from "components/AdMarqueeCard/MarqueeAd";
import ChevronIcon from "components/Icons/ChevronIcon";
import Spinner from "components/Icons/Spinner";

type Props = {
  allowAutoplay?: boolean;
  autoplayInterval?: number;
  pageType: AdMarqueePageLocation;
  creatives?: Creative[];
};
const AdMarqueeCarousel: React.FC<Props> = ({
  allowAutoplay = true,
  autoplayInterval = 5000,
  pageType,
  creatives: initialCreatives,
}) => {
  const { creatives, loading: loadingMarqueeCreatives } =
    useFetchMarqueeCreatives(pageType);

  return (
    <div className="relative w-full" id="ad-marquee-carousel">
      {!initialCreatives && loadingMarqueeCreatives && (
        <div className="absolute left-0 bottom-1/3 right-0 z-10">
          <Spinner />
        </div>
      )}
      <Splide
        data-testid="ad-marquee-carousel"
        aria-label="Advertising Carousel"
        options={{
          autoplay: allowAutoplay,
          interval: autoplayInterval,
          rewind: true,
        }}
        hasTrack={false}
      >
        {/*
          Because the Marquee Ad's height is dynamic, we we can't provide a default, static height for
          #ad-marquee-carousel. We also do not want the height to change post initial render as that can affect
          SEO (see: https://web.dev/cls). To resolve this, while creatives are loading, we render <Spinner /> above
          set <SplideTrack /> as invisible, and use the fallBackHouseAd as a stand in for the eventual creatives.
        */}
        <SplideTrack
          className={
            !initialCreatives && loadingMarqueeCreatives
              ? "invisible block"
              : ""
          }
        >
          {(initialCreatives?.length || creatives.length
            ? initialCreatives || creatives
            : [fallBackHouseAd]
          ).map((ad, index) => {
            const isDispensaryAd = ad.creativeType === AdMarqueeType.DISPENSARY;
            const trackingCategory = setTrackingCategory(
              isDispensaryAd,
              pageType,
            );
            return (
              <SplideSlide
                key={
                  /*
                  if creatives haven't yet loaded or an empty array is returned, we're using the fallback
                  house creative. Need to update the key when switching from fallback to real data
                */
                  initialCreatives?.length || creatives.length
                    ? index
                    : "fallback"
                }
              >
                <div className="md:hidden block w-full">
                  <MarqueeAdCard
                    ad={ad}
                    trackingCategory={trackingCategory}
                    slot={index}
                    marqueeAdType={MarqueeAdType.Mobile}
                  />
                </div>
                <div className="hidden md:block w-full">
                  <MarqueeAdCard
                    ad={ad}
                    trackingCategory={trackingCategory}
                    slot={index}
                    marqueeAdType={MarqueeAdType.Desktop}
                  />
                </div>
              </SplideSlide>
            );
          })}
        </SplideTrack>
        {/*
            Details for custom pagination: https://splidejs.com/integration/react-splide/#custom-structure
            react-splide allows you to seperate the arrows/pagination from the track by using hasTrack={false}
            and HTML placeholders. The below HTML simply defines the structure of the arrows/pagination and
            defines custom arrows.

            Note that splide tries to apply its own `transform` rule to the SVGs in these buttons, so we have
            to add a `!transform` class to the SVGs to ensure that tailwind transformations work as expected.
          */}
        <div className="splide__arrows">
          <button className="splide__arrow splide__arrow--prev">
            <ChevronIcon className="!transform" direction="left" height="12" />
          </button>
          <ul className="splide__pagination">
            <li></li>
          </ul>
          <button className="splide__arrow splide__arrow--next">
            <ChevronIcon className="!transform" direction="right" height="12" />
          </button>
        </div>
      </Splide>

      <style jsx global>{`
        #ad-marquee-carousel :global(.splide__arrows) {
          margin-top: 1em;
          display: flex;
          justify-content: center;
          align-items: center;
          position: static;
        }
        #ad-marquee-carousel :global(.splide__arrows) :global(.splide__arrow) {
          position: static;
          transform: unset;
          height: 1.5em;
          width: 1.5em;
          background-color: #ffffff;
          box-shadow:
            0 4px 6px -1px rgb(0 0 0 / 0.1),
            0 2px 4px -2px rgb(0 0 0 / 0.1);
        }
        #ad-marquee-carousel :global(.splide__pagination) {
          position: static;
          display: flex;
          align-items: center;
        }
        #ad-marquee-carousel :global(.splide__pagination__page.is-active) {
          transform: scale(1);
          background-color: #333333;
          opacity: 1;
        }
        #ad-marquee-carousel :global(.splide__pagination__page) {
          transform: scale(1);
          height: 8px;
          width: 8px;
          opacity: 0.3;
          transition: background-color 420ms ease;
          background-color: #333333;
          margin: 4px;
        }
        .splide__track.invisible.block .splide__list {
          display: block;
        }
      `}</style>
    </div>
  );
};

export default AdMarqueeCarousel;
