export enum AdMarqueePageLocation {
  HOME_PAGE = "homepage",
  STRAINS_LIST_PAGE = "strains_lists",
  DEALS_PAGE = "deals_page",
}

export enum AdMarqueeType {
  HOUSE = "house",
  DISPENSARY = "dispensary",
  BRAND = "brand",
}

export type Creative = {
  backgroundColor: string | null;
  backgroundImageDesktop: string | null;
  backgroundImageMobile: string | null;
  buttonColor: string;
  buttonText: string | null;
  buttonTextColor: string | null;
  creativeType: AdMarqueeType;
  destinationUrl: string;
  dispensaryId: number | null;
  brandId: number | null;
  headline: string;
  imageUrl: string | null;
  merchandisingCampaignId: number | null;
  textColor: string | null;
  topLine: string | null;
};

export const BACKGROUND_IMAGE_DESKTOP_WIDTH = 992;
export const BACKGROUND_IMAGE_DESKTOP_HEIGHT = 207;
export const BACKGROUND_IMAGE_MOBILE_WIDTH = 343;
export const BACKGROUND_IMAGE_MOBILE_HEIGHT = 215;
