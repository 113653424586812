import consumerApi from "api/services/consumer-api";
import { AdMarqueeType, Creative } from "custom-types/AdMarquee";
import logError from "utils/logError";

export const fallBackHouseAd = {
  backgroundColor: "#DEF3FF",
  backgroundImageDesktop: null,
  backgroundImageMobile: null,
  brandId: null,
  buttonColor: "#017C6B",
  buttonText: "get the app",
  buttonTextColor: "#FFFFFF",
  creativeType: AdMarqueeType.HOUSE,
  destinationUrl:
    "https://onelink.to/leaflymarketing?itm_source=blast&itm_medium=marquee&itm_campaign=trf-all-app-marquee-usa-all",
  dispensaryId: null,
  headline: "Download your portable budtender",
  imageUrl:
    "https://directus-media.leafly.com/85bade3a-41fa-431a-a1e2-398c7b8ccd3b.png?auto=compress,format&w=100&dpr=2",
  merchandisingCampaignId: null,
  textColor: "#333333",
  topLine: "Leafly",
};

const getMarqueeCreatives = async (
  pageType: string,
  lat: number,
  lon: number,
) => {
  try {
    const {
      data: { marqueeCreatives },
    } = await consumerApi.get<{ marqueeCreatives: Creative[] }>(
      `/api/marquee_creatives/v1?lat=${lat}&lon=${lon}&page_type=${pageType}`,
    );

    if (marqueeCreatives.length < 1) {
      return [fallBackHouseAd];
    }

    return marqueeCreatives;
  } catch (e) {
    logError(e.message, {
      functionName: "getMarqueeCreatives",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return [fallBackHouseAd];
  }
};

export default getMarqueeCreatives;
