import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";

import useAlternateUrl from "hooks/useAlternateUrl";
import useCanonical from "hooks/useCanonical";
import useDomain from "hooks/useDomain";

const MetaTags: React.FC<{
  canonicalParams?: string[];
  canonicalOverride?: string;
  children?: React.ReactNode;
  description?: string;
  image?: string;
  robots?: string;
  shouldShowAlternates?: boolean;
  title: string;
}> = ({
  canonicalParams,
  canonicalOverride,
  children,
  description,
  image = "https://public.leafly.com/web-home/logo-1200x630.png",
  robots,
  shouldShowAlternates = true,
  title,
}) => {
  const { asPath } = useRouter();

  let canonicalUrl = useCanonical(canonicalParams);
  const { usUrl, caUrl } = useAlternateUrl();
  const domain = useDomain();

  if (canonicalOverride) {
    canonicalUrl = `${domain}${canonicalOverride}`;
  }

  const shouldShowCanonical = canonicalUrl && !robots?.includes("noindex");

  return (
    <Head>
      {robots && <meta name="robots" content={robots} />}

      <title>{title}</title>
      {shouldShowCanonical && <link rel="canonical" href={canonicalUrl} />}
      {description && <meta name="description" content={description} />}
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      <meta property="og:url" content={`${domain}${asPath}`} />
      {image && <meta property="og:image" content={image} />}

      {shouldShowAlternates && (
        <>
          <link href={`${usUrl}`} hrefLang="en-us" rel="alternate" />
          <link href={`${caUrl}`} hrefLang="en-CA" rel="alternate" />
        </>
      )}

      {children}
    </Head>
  );
};

export default MetaTags;
