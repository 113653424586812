import React from "react";
import classNames from "classnames";
import Link from "next/link";

import { Action } from "constants/events";
import {
  AdMarqueeType,
  BACKGROUND_IMAGE_DESKTOP_HEIGHT,
  BACKGROUND_IMAGE_DESKTOP_WIDTH,
  BACKGROUND_IMAGE_MOBILE_HEIGHT,
  BACKGROUND_IMAGE_MOBILE_WIDTH,
  Creative,
} from "custom-types/AdMarquee";
import { useEventTracker } from "hooks/useEventTracker";
import generateImgixUrl from "utils/image/generateImgixUrl";
import isHexColorBright from "utils/isHexColorBright";

import AdFlag from "components/botanic/AdFlag";
import Button from "components/botanic/Button";
import TrackImpression from "components/TrackImpression";

const MarqueeText = ({
  topLine,
  headline,
  textColor,
}: {
  topLine: string | null;
  headline: string | null;
  textColor: string | null;
}) => {
  const textStyle = {
    color: textColor || "",
  };
  return (
    <div className="pl-4 md:pl-16 lg:pl-20 font-extrabold flex flex-col justify-end w-full">
      <p className="mb-0 text-xs" style={textStyle}>
        {topLine}
      </p>
      <p className="mb-0 text-md" style={textStyle}>
        {headline}
      </p>
    </div>
  );
};

const MarqueeButton = ({
  buttonColor,
  buttonTextColor,
  buttonText,
}: {
  buttonColor: string | null;
  buttonTextColor: string | null;
  buttonText: string | null;
}) => {
  return (
    <div className="pl-4 md:pl-16 lg:pl-20 w-fit">
      <Button
        className="min-w-[10rem]"
        style={{
          backgroundColor: buttonColor || undefined,
          color: buttonTextColor || undefined,
        }}
      >
        {buttonText || "Shop Now"}
      </Button>
    </div>
  );
};

const MarqueeImage = ({
  imageUrl,
  creativeType,
  isMobile,
}: {
  imageUrl: string;
  creativeType: AdMarqueeType;
  isMobile: boolean;
}) => {
  const imageClassNames = classNames(
    "md:rounded-tr rounded-br bottom-0 right-0 h-full flex justify-end",
  );

  const sharedImageProps = {
    auto: "compress,format",
    h: String(
      isMobile
        ? BACKGROUND_IMAGE_MOBILE_HEIGHT
        : BACKGROUND_IMAGE_DESKTOP_HEIGHT,
    ),
  };

  const highResImage = generateImgixUrl(imageUrl, {
    ...sharedImageProps,
    dpr: "2",
  });

  return (
    <div className={imageClassNames} role="presentation">
      <img
        srcSet={`${generateImgixUrl(imageUrl, {
          ...sharedImageProps,
          dpr: "1",
        })} 1x, ${highResImage} 2x`}
        src={highResImage}
        alt={`${creativeType} ad`}
      />
    </div>
  );
};

export const AdvancedMarqueeDesktopAd = ({
  buttonColor,
  buttonTextColor,
  buttonText,
  textColor,
  topLine,
  headline,
  backgroundImageDesktop,
}: Creative) => {
  return (
    <div
      data-testid="advanced-marquee-desktop-ad"
      className="bg-left-top bg-no-repeat bg-contain grid grid-cols-[1fr_254px] items-center overflow-hidden grid-rows-1"
      style={{
        aspectRatio: `${BACKGROUND_IMAGE_DESKTOP_WIDTH} / ${BACKGROUND_IMAGE_DESKTOP_HEIGHT}`,
        backgroundImage: `url("${
          backgroundImageDesktop &&
          generateImgixUrl(backgroundImageDesktop, {
            auto: "compress,format",
            dpr: "2",
            h: String(BACKGROUND_IMAGE_DESKTOP_HEIGHT),
          })
        }")`,
      }}
    >
      <div className="flex flex-auto flex-col gap-2 lg:gap-4">
        <MarqueeText
          topLine={topLine}
          headline={headline}
          textColor={textColor}
        />
        <MarqueeButton
          buttonText={buttonText}
          buttonColor={buttonColor}
          buttonTextColor={buttonTextColor}
        />
      </div>
    </div>
  );
};

export const AdvancedMarqueeMobileAd = ({
  buttonColor,
  buttonTextColor,
  buttonText,
  textColor,
  topLine,
  headline,
  backgroundImageMobile,
}: Creative) => {
  return (
    <div
      data-testid="advanced-marquee-mobile-ad"
      className="grid grid-cols-[5fr_3fr] grid-rows-[1fr] bg-left-top bg-no-repeat bg-cover"
      style={{
        aspectRatio: `${BACKGROUND_IMAGE_MOBILE_WIDTH} / ${BACKGROUND_IMAGE_MOBILE_HEIGHT}`,
        backgroundImage: `url("${
          backgroundImageMobile &&
          generateImgixUrl(backgroundImageMobile, {
            auto: "compress,format",
            dpr: "2",
            h: String(BACKGROUND_IMAGE_MOBILE_HEIGHT),
          })
        }")`,
      }}
    >
      <div className="col-start-1 col-span-1 row-start-1 row-span-2 flex flex-col justify-center gap-2 lg:gap-4">
        <MarqueeText
          topLine={topLine}
          headline={headline}
          textColor={textColor}
        />
        <MarqueeButton
          buttonText={buttonText}
          buttonColor={buttonColor}
          buttonTextColor={buttonTextColor}
        />
      </div>
    </div>
  );
};

export const BasicMarqueeDesktopAd = ({
  buttonColor,
  buttonTextColor,
  buttonText,
  textColor,
  topLine,
  headline,
  imageUrl,
  creativeType,
}: Creative) => {
  return (
    <div
      data-testid="basic-marquee-desktop-ad"
      className="bg-left-top bg-no-repeat bg-contain grid grid-cols-[1fr_254px] items-center overflow-hidden grid-rows-1"
      style={{
        aspectRatio: `${BACKGROUND_IMAGE_DESKTOP_WIDTH} / ${BACKGROUND_IMAGE_DESKTOP_HEIGHT}`,
      }}
    >
      <div className="flex flex-col gap-2 lg:gap-4">
        <MarqueeText
          topLine={topLine}
          headline={headline}
          textColor={textColor}
        />
        <MarqueeButton
          buttonText={buttonText}
          buttonColor={buttonColor}
          buttonTextColor={buttonTextColor}
        />
      </div>
      {imageUrl && (
        <MarqueeImage
          imageUrl={imageUrl}
          creativeType={creativeType}
          isMobile={false}
        />
      )}
    </div>
  );
};

export const BasicMarqueeMobileAd = ({
  buttonColor,
  buttonTextColor,
  buttonText,
  textColor,
  topLine,
  headline,
  imageUrl,
  creativeType,
}: Creative) => {
  return (
    <div
      data-testid="basic-marquee-mobile-ad"
      className="grid grid-cols-[5fr_3fr] grid-rows-[1fr]"
      style={{
        aspectRatio: `${BACKGROUND_IMAGE_MOBILE_WIDTH} / ${BACKGROUND_IMAGE_MOBILE_HEIGHT}`,
      }}
    >
      <div className="col-start-1 col-span-1 row-start-1 row-span-2 flex flex-col justify-center gap-2 lg:gap-4">
        <MarqueeText
          topLine={topLine}
          headline={headline}
          textColor={textColor}
        />
        <MarqueeButton
          buttonText={buttonText}
          buttonColor={buttonColor}
          buttonTextColor={buttonTextColor}
        />
      </div>
      {imageUrl && (
        <div className="col-start-2 col-span-1 row-start-2 row-span-1">
          <MarqueeImage
            imageUrl={imageUrl}
            creativeType={creativeType}
            isMobile={true}
          />
        </div>
      )}
    </div>
  );
};

export enum MarqueeAdType {
  Desktop = "Desktop",
  Mobile = "Mobile",
}

export const MarqueeAdCard = ({
  trackingCategory,
  slot,
  ad,
  marqueeAdType,
}: {
  ad: Creative;
  trackingCategory: string;
  slot: number;
  marqueeAdType: MarqueeAdType;
}) => {
  const {
    backgroundColor,
    dispensaryId,
    brandId,
    merchandisingCampaignId,
    destinationUrl,
    creativeType,
  } = ad;
  const brightnessCheck = !!(
    backgroundColor && isHexColorBright(backgroundColor, 0.85)
  );
  const cardClassNames = classNames(
    "ad-marquee-card rounded-t rounded-b overflow-hidden",
    {
      "border border-light-grey": brightnessCheck,
    },
  );
  const { publishEvent } = useEventTracker();
  const isNotHouseAd = [AdMarqueeType.DISPENSARY, AdMarqueeType.BRAND].includes(
    creativeType,
  );
  const isAdvancedMarquee = !!(
    ad.backgroundImageDesktop && ad.backgroundImageMobile
  );
  const MarqueeAdChild = React.useMemo(() => {
    if (marqueeAdType === MarqueeAdType.Desktop && isAdvancedMarquee) {
      return AdvancedMarqueeDesktopAd;
    } else if (marqueeAdType === MarqueeAdType.Desktop) {
      return BasicMarqueeDesktopAd;
    } else if (marqueeAdType === MarqueeAdType.Mobile && isAdvancedMarquee) {
      return AdvancedMarqueeMobileAd;
    } else {
      return BasicMarqueeMobileAd;
    }
  }, [marqueeAdType, isAdvancedMarquee]);
  return (
    <TrackImpression
      className="w-full"
      trackingFunction={() => {
        publishEvent({
          action: Action.impression,
          ...(isNotHouseAd && {
            brandId: brandId,
            dispensaryId: dispensaryId,
            merchandisingCampaignId: merchandisingCampaignId,
          }),
          category: trackingCategory,
          label: `slot #${slot + 1}`,
        });
      }}
    >
      <div
        className={cardClassNames}
        style={{ backgroundColor: backgroundColor || "" }}
        data-testid="ad-card-container"
      >
        <Link
          href={destinationUrl}
          rel={isNotHouseAd ? "sponsored" : ""}
          onClick={() => {
            publishEvent({
              action: Action.click,
              ...(isNotHouseAd && {
                brandId: brandId,
                dispensaryId: dispensaryId,
                merchandisingCampaignId: merchandisingCampaignId,
              }),
              category: trackingCategory,
              label: `slot #${slot + 1}`,
            });
          }}
        >
          <MarqueeAdChild {...ad} />
          <AdFlag promoted={isNotHouseAd} position={"right"} />
        </Link>
      </div>
    </TrackImpression>
  );
};

export default MarqueeAdCard;
